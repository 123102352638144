@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=Poppins:wght@400;500;600;700&family=Roboto:wght@300;400;500;700&family=Work+Sans:wght@400;500;600&display=swap");
html {
  font-size: 16px;
}
body {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #000;
  background-color: #fff;
}
p {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.18;
  color: #515151;
  margin-bottom: 16px;
}
p:last-child {
  margin-bottom: 0;
}
a {
  color: #1976d2;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}
::placeholder {
  font-weight: 400 !important;
  color: #000 !important;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 50px;
}
.mt-16,
.mx-16 {
  margin-top: 16px;
}
.mb-16,
.mx-16 {
  margin-bottom: 16px;
}
.mt-20,
.mx-20 {
  margin-top: 20px;
}
.mb-20,
.mx-20 {
  margin-bottom: 20px;
}
.mt-24,
.mx-24 {
  margin-top: 24px;
}
.mb-24,
.mx-24 {
  margin-bottom: 24px;
}
.mb-34,
.mx-34 {
  margin-bottom: 34px;
}
.btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.18;
  text-align: center;
  color: #fff;
  padding: 15px 24px 12px;
  border-radius: 50px;
  display: inline-block;
  border-width: 0 0 3px 0;
  border-style: solid;
  box-shadow: none !important;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
.btn-pink {
  background-color: #ef5166;
  border-color: #d8384d;
}
.btn-pink:hover {
  color: #fff;
  background-color: #d8384d;
  border-color: #00000041;
}
.btn-parrot {
  background-color: #66bf7a;
  border-color: #48a361;
}
.btn-parrot:hover {
  color: #fff;
  background-color: #48a361;
  border-color: #00000041;
}
.btn-outline-secondary {
  border-width: 1px 1px 3px 1px;
  border-style: solid;
  border-color: #e8e8e8;
  color: #4b5360;
  background: #fff;
}
.btn-outline-secondary:hover {
  color: #4b5360;
  background-color: #fafafa;
  border-color: #dfdfdf;
}
.btn-outline-primary {
  border-width: 1px 1px 3px 1px;
  border-style: solid;
  border-color: #e8e8e8;
  color: #ef5166;
  background: #fff;
}
.btn-outline-primary:hover {
  background-color: #ef5166;
  border-color: #d8384d;
}
.btn-outline-grey {
  border-width: 1px 1px 3px 1px;
  border-style: solid;
  border-color: #e8e8e8;
  border-radius: 50px;
  font-weight: 600;
  color: #212121;
  padding: 8px 36px 6px 16px;
  background-image: url(../img/svg/chevron-right.svg);
  background-position: right 17px center;
  background-size: 7px;
  background-repeat: no-repeat;
  transition: all 0.4s ease-in-out;
}
.btn-outline-grey:hover {
  background-color: #fafafa;
}
.btn-sm {
  font-weight: 400;
  border-width: 1px 1px 2px 1px;
  padding: 6px 12px 4px 12px;
}
.login-section {
  height: 100vh;
}
.login-section.with-bg {
  background-image: url(../img/register-banner.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.login-upper-section {
  height: 93%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
.login-upper-section > div,
.login-upper-section > div > div {
  height: 100%;
}
.login-inner {
  box-shadow: 0px 24px 74px rgb(0 0 0 / 3%);
  border-radius: 24px;
  background-color: #fff;
  padding: 48px;
  max-width: 580px;
  margin-left: auto;
  margin-right: 60px;
}
.login-title {
  display: block;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
  color: #212121;
  margin-bottom: 24px;
}
.login-text {
  display: block;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.18;
  color: #8e8e8e;
  margin-bottom: 35px;
}
.login-form-inner .form-group {
  margin-bottom: 24px;
}
.login-form-inner .form-control {
  padding: 15px;
  color: #212121;
  background-color: transparent;
  border: 1px solid #dbdbdb;
  border-radius: 50px;
  line-height: 1.18;
  font-size: 14px;
  font-weight: 700;
  box-shadow: none !important;
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 16px;
  resize: none;
}
.login-form-inner .form-control.email,
.login-form-inner .form-control.phone,
.login-form-inner .form-control.password {
  padding: 15px 16px 15px 42px;
}
.login-form-inner .form-control.email {
  background-image: url(../img/svg/mail.svg);
}
.login-form-inner .form-control.phone {
  background-image: url(../img/svg/phone-line.svg);background-size: 12px;
}
.login-form-inner .form-control.password{
  background-image: url(../img/svg/password.svg);
}
.login-form-inner .form-label {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
.login-form-inner .form-label span {
  color: #ef5166;
}
.login-form-trouble {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.1;
}
.login-image .dashboard-card {
  min-width: 262px;
  position: absolute;
}
.login-image .dashboard-card.is-total-appraisals {
  top: -76px;
  left: 295px;
  box-shadow: -30px 62px 101px rgba(0, 0, 0, 0.12);
  z-index: 2;
  animation-name: float-bob;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.login-image .dashboard-card.is-total-won {
  top: 65px;
  left: 180px;
  border: none;
  box-shadow: 0px 32px 81px rgba(0, 0, 0, 0.08);
  z-index: 1;
  animation-name: float-bob-up;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotateme{0%{transform:rotate(0deg)}to{transform:rotate(-1turn)}}@keyframes float-bob{0%{transform:translateY(0)}50%{transform:translateY(-15px)}to{transform:translateY(0)}}@keyframes float-bob-up{0%{transform:translateY(-15px)}50%{transform:translateY(0)}to{transform:translateY(-15px)}}


.form-group-with-password {
  position: relative;
}
.form-group-with-password .icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.login-form-inner .btn {
  width: 100%;
  display: block;
  margin-bottom: 32px;
}
.login-form-register-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.18;
  padding: 15px;
  color: #000;
  background-color: transparent;
  border: 1px solid #dbdbdb;
  border-radius: 50px;
}
.login-form-register-link a {
  color: inherit;
  text-decoration-line: underline;
  font-weight: 700;
  margin-left: 5px;
  transition: all 0.4s ease-in-out;
}
.login-form-register-link a:hover {
  color: #1976d2;
}
.login-form-login-link {
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.18;
  color: #000;
  background-color: transparent;
  border-radius: 50px;
}
.login-form-login-link a {
  color: inherit;
  text-decoration-line: underline;
  font-weight: 700;
  margin-left: 5px;
  transition: all 0.4s ease-in-out;
}
.login-form-login-link a:hover {
  color: #ef5166;
}
.login-form-login-link i {
  font-size: 24px;
  margin-right: 5px;
}
.login-copyright {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-copyright span {
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #8c8c8c;
  display: inline-block;
}
.login-copyright span a{color: #8c8c8c;transition: all 0.4s ease-in-out;}
.login-copyright span a:hover{color: #d8384d;}
.login-copyright span:first-child {
  margin-right: 48px;
}
.login-image {
  position: relative;
}
.dashboard-card {
  background-color: #fff;
  border-width: 1px 1px 2px 1px;
  border-style: solid;
  border-color: #ededed;
  border-radius: 16px;
  padding: 24px;
  position: relative;
}
.dashboard-card-row {
  display: flex;
  flex-wrap: wrap;
}
.dashboard-card-icon {
  flex: 0 0 40px;
  max-width: 40px;
  height: 40px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(245, 245, 245, 0) 0%,
    rgba(245, 245, 245, 0.55) 100%
  );
  border-radius: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
}
.dashboard-card-text {
  flex: 1;
  padding-left: 8px;
  padding-right: 24px;
}
.dashboard-card-count {
  font-weight: 800;
  font-size: 36px;
  line-height: 1.16;
  color: #4b5360;
  display: block;
  margin-bottom: 8px;
}
.dashboard-card-label {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.1;
  color: #4b5360;
  display: block;
  margin-bottom: 8px;
}
.dashboard-card .progress {
  height: 4px;
  background-color: #f2f2f2;
}
.is-total-appraisals .dashboard-card-icon {
  color: #3098ff;
}
.is-total-appraisals .progress .progress-bar {
  background-color: #1ad0ff;
}
.is-total-won .dashboard-card-icon {
  color: #58ba6e;
}
.is-total-won .progress .progress-bar {
  background-color: #58ba6e;
}
.is-new-appraisal .dashboard-card-icon {
  color: #e872a3;
}
.is-new-appraisal .progress .progress-bar {
  background-color: #e872a3;
}
.is-offer-made .dashboard-card-icon {
  color: #767d88;
}
.is-offer-made .progress .progress-bar {
  background-color: #767d88;
}
.is-no-deals .dashboard-card-icon {
  color: #f29903;
}
.is-no-deals .progress .progress-bar {
  background-color: #f29903;
}
.is-working .dashboard-card-icon {
  color: #ef5166;
}
.is-working .progress .progress-bar {
  background-color: #ef5166;
}
.login-image .total-appraisals-added-card {
  position: absolute;
  top: -76px;
  right: 22px;
  background-color: #fff;
  box-shadow: 0px 32px 41px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 30px 24px;
  width: 277px;
  animation-name: float-bob-up;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.login-image .total-appraisals-count{font-size: 45px;}
.login-image .total-appraisals-text{font-size: 24px;}
.login-image .total-appraisals-added-card p{font-size: 14px;}
.login-image .dashboard-card{padding: 24px;}
.dashboard-featured .dashboard-card.total-appraisals-added-card, .dashboard-featured .dashboard-card.dashboard-biggest-deal{/*padding-bottom: 74px;*/}
.dashboard-featured .total-appraisals-button-wrap,.dashboard-featured .dashboard-biggest-deal-profile{margin-top: 35px; /*position: absolute; bottom: 24px;right: 24px;*/}
.total-appraisals-added-card {padding: 32px;}


.total-appraisals-count {
  font-weight: 700;
  font-size: 60px;
  line-height: 1.27;
  color: #60b573;
  margin-bottom: 5px;
  display: block;
}
.total-appraisals-text {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
  color: #212121;
}
.total-appraisals-added-card p {
  font-weight: 400;
  font-size: 21px;
  color: #3c3c3c;
}
.total-appraisals-button-wrap {
  margin-top: 35px;
  text-align: right;
}
.total-appraisals-button-wrap .btn{border-width: 0 0 4px 0;}
.register-hero-text {
  background: linear-gradient(
    127.82deg,
    rgba(247, 247, 247, 0.9) -2.8%,
    rgba(247, 253, 255, 0.54) 74%,
    rgba(247, 247, 247, 0.9) 71.85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-weight: 800;
  font-size: 96px;
  line-height: 1;
  max-width: 594px;
  margin: auto;
}
.reset-password-section {
  background-image: url(../img/line-shape.svg);
  background-position: bottom center;
  background-size: 100%;
  background-repeat: no-repeat;
}
.login-tabs ul {
  background-color: #f5f5f5;
  border-radius: 60px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 5px;
  margin-bottom: 24px;
}
.login-tabs li {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: #8C9199;
  border-radius: 50px;
  padding: 10px;
  flex: 0 0 50%;
  max-width: 50%;
  font-family: 'Poppins', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.4s ease-in-out;
}
.login-tabs li i {
  margin-right: 10px;
}
.login-tabs li:hover,
.login-tabs li.active {
  background-color: #fff;
  color: #212121;
}
.form-privacy-policy {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.25;
  color: #747b87;
  display: block;
  text-align: center;
}
.form-privacy-policy a {
  color: #0194ba;
}
.dashboard-navbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 12px 33px;
  border-bottom: 1px solid #ededed;
}
.dashboard-navbar-logo a {
  display: inline-block;
  vertical-align: middle;
}
.dashboard-navbar-logo img {
  width: 120px;
}
.dashboard-navbar-search .form-control {
  border-radius: 50px;
  padding: 10px 15px 10px 42px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  background-color: #f7f7f7;
  background-image: url(../img/svg/search.svg);
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 16px;
  width: 38vw;
  border: none;
  box-shadow: none !important;
}
.dashboard-navbar-search .form-control::placeholder {
  color: #4b5360 !important;
}
.dashboard-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.dashboard-links li {
  display: inline-block;
  margin-right: 32px;
}
.dashboard-links li:last-child {
  margin-right: 0;
}
.dashboard-links li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #4b5360;
  font-family: "Roboto", sans-serif;
}
.dashboard-links li a:hover {
  color: #ef5166;
}
.dashboard-button {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.dashboard-button-box {
  margin-right: 16px;
}
.dashboard-button-box:last-child {
  margin-right: 0;
}
.dashboard-button-box .btn-pink {
  font-weight: 500;
  font-size: 14px;
  padding: 11px 17px 8px;
}
.dashboard-button-box .btn-pink i {
  margin-right: 13px;
  vertical-align: -1px;
}
.dashboard-button-box .btn-icon {
  width: 40px;
  height: 40px;
  background-color: #fbfbfb;
  border: 1px solid #f9f9f9;
  color: #33577b;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon.is-new {
  position: relative;
}
.icon.is-new::after {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  background: #ff3d00;
  border-radius: 64px;
  top: 0;
  right: 0;
}
.dashboard-profile-dropdown {
  position: relative;
}
.dashboard-profile-dropdown-button {
  display: flex;
  align-items: center;
  background-color: #f0fcff;
  border-radius: 64px;
  color: #4b5360;
  padding: 6px 13px 6px 6px;
  border: none;
}
.dashboard-profile-dropdown-button img {
  margin-right: 8px;
  width: 33px;
  height: 33px;
  object-fit: cover;
  border-radius: 60px;
}
.dashboard-profile-dropdown span {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 12px;
  margin-right: 10px;
}
.dashboard-profile-dropdown i {
  font-size: 9px;
  color: #a6a6a6;
}
.dashboard-profile-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 9;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 10px 15px -10px #dfdfdf;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  opacity: 0;
  visibility: hidden;
}
.dashboard-profile-dropdown ul.show {
  opacity: 1;
  visibility: visible;
}
.dashboard-profile-dropdown li {
  display: block;
}
.dashboard-profile-dropdown li a {
  color: #4b5360;
  font-size: 14px;
  display: block;
  padding: 7px;
  border-top: 1px solid #e6e6e6;
}
.dashboard-profile-dropdown li:first-child a {
  border-top: 0;
}
.dashboard-profile-dropdown li a:hover {
  color: #ef5166;
}
.menuIcon {
  display: none;
  width: 32px;
  height: 32px;
  position: relative;
  padding: 0px;
  margin-right: 5px;
  color: #000;
}
.menuIcon span {
  display: block;
  width: 27px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: currentColor;
  margin: auto;
}
.menuIcon span:before {
  content: "";
  width: 21px;
  position: absolute;
  top: -8px;
  left: 0;
  height: 3px;
  background: currentColor;
}
.menuIcon span:after {
  content: "";
  width: 16px;
  position: absolute;
  bottom: -8px;
  left: 0;
  height: 3px;
  background: currentColor;
}
.menuIcon span,
.menuIcon span::after,
.menuIcon span::before {
  transition: all 0.4s ease-in-out;
}
.dashboard-wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: calc(100vh - 70px);
}
.dashboard-inner {
  flex: 1;
  padding: 32px;
  background-color: #fbfbfb;
  overflow: auto;
  height: 100%;
  overflow-x: hidden;
}
.dashboard-sidebar {
  padding: 32px 0 0px 0;
  flex: 0 0 167px;
  max-width: 167px;
  border-right: 1px solid #ededed;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashboard-sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.dashboard-sidebar li {
  display: block;
  margin-bottom: 8px;
}
.dashboard-sidebar li:last-child {
  margin-bottom: 0;
}
.dashboard-sidebar li a {
  padding: 12px 15px 12px 24px;
  display: flex;
  align-items: center;
  border-right: 2px solid transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #212121;
}
.dashboard-sidebar li a i {
  margin-right: 12px;
  color: #78818d;
  font-size: 18px;
}
.dashboard-sidebar li a:hover {
  color: #ef5166;
}
.dashboard-sidebar li a.active {
  background: linear-gradient(
    270deg,
    #fff2f2 0%,
    rgba(255, 255, 255, 0) 62.24%
  );
  border-color: #ef5166;
  font-weight: 700;
}
.dashboard-sidebar li a.active i {
  color: #ef5166;
}
.dashboard-sidebar hr {
  background-color: #b1b1b1;
  margin-left: 21px;
  margin-right: 21px;
}
.page-title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 1;
  color: #212121;
  margin: 0;
}
.dashboard-card-filter {
  margin-bottom: 10px;
}
.dashboard-card-select .form-select,
.dashboard-count-filter .form-select {
  padding: 12px 36px 12px 16px;
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 50px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #212121;
  box-shadow: none !important;
  background-image: url(../img/svg/chevron-down.svg);
  background-size: 14px;
  background-position: right 15px center;
}
.card-title {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  color: #212121;
}
.dashboard-card-select .form-select {
  background-color: #f7f7f7;
  border-color: #ededed;
}
.dashboard-card .table tbody td {
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #212121;
  vertical-align: middle;
  padding: 14px 16px;
  white-space: nowrap;
  border: none;
  overflow: hidden;
}
.dashboard-card .table thead th {
  padding: 18px 16px;
  color: #747b87;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  white-space: nowrap;
}
.dashboard-card .table img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50px;
}
.dashboard-card .table > :not(:last-child) > :last-child > * {
  border-bottom-color: #efefef;
}
.dashboard-top-employees-profile,
.table-vehicle-image,
.table-user-image {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  position: relative;
}
.dashboard-top-employees-profile img {
  border: 2px solid #f29903;
}
.dashboard-top-employees-profile::after {
  content: "";
  width: 16px;
  height: 16px;
  background-image: url(../img/star.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  right: -5px;
}
.dashboard-card .table .total-won {
  color: #24953d;
}
.dashboard-card .table .total-amount {
  color: #ce8100;
}
.dashboard-top-employees {
  max-height: 27vh;
  overflow: auto;
  height: 100%;
}
.dashboard-featured .dashboard-card {
  height: 100%;
}
.table {
  margin-bottom: 0;
}
.dashboard-biggest-deal {
  background-color: #e7faf1;
  border-width: 1px 1px 2px 1px;
  border-style: solid;
  border-color: #def1e8;
}
.deal-user-image img {
  height: 59px;
  width: 59px;
  border-radius: 100px;
  object-fit: cover;
  margin-right: 16px;
}
.dashboard-biggest-deal-user {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #ccebd9;
}
.dashboard-biggest-deal-user-info h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  color: #184221;
  margin: 0 0 4px 0;
  display: block;
}
.dashboard-biggest-deal-user-info span {
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #3a5f42;
}
.dashboard-biggest-deal h2 {
  font-weight: 600;
  font-size: 30px;
  line-height: 1.2;
  color: #184221;
  margin-bottom: 8px;
  display: block;
}
.dashboard-biggest-deal p {
  font-weight: 400;
  font-size: 21px;
  line-height: 1.2;
  color: #13361b;
}
.dashboard-biggest-deal-profile {
  margin-top: 35px;
  text-align: right;
}
.table-sort {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #09121f;
  display: inline-block;
  vertical-align: 2px;
  margin-left: 14px;
  cursor: pointer;
}
.table-sort.asc {
  border-bottom: 6px solid #09121f;
  border-top: none;
}
.dashboard-latest-apraisals .table tbody td {
  border-bottom: 1px solid #f7f7f7;
  padding-top: 8px;
  padding-bottom: 8px;
}
.dashboard-latest-apraisals .table tbody tr:last-child td {
  border-bottom: 0;
}
.dashboard-card .table .table-vehicle-image img {
  border-radius: 4px;
}
.dashboard-card .table .table-user-image img {
  border-radius: 8px;
}
.dashboard-card .table .btn-icon {
  background-color: transparent;
  color: #747b87;
  font-size: 20px;
  margin-right: 18px;
  box-shadow: none;
  outline: none;
  border: none;
  padding: 0;
  transition: all 0.3s ease-in-out;
}
.dashboard-card .table .btn-icon:hover {
  color: #ef5166;
}
.status-won,
.status-pending,
.status-rejected,
.status-new {
  padding: 6px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #ffffff;
  border-radius: 50px;
}
.status-rejected,
.offer-details-inner .form-group .form-select.status-rejected {
  background-color: #ef5166;
}
.status-pending,
.offer-details-inner .form-group .form-select.status-pending {
  background-color: #f29903;
}
.status-new,
.offer-details-inner .form-group .form-select.status-new {
  background-color: #1976d2;
}
.status-won,
.offer-details-inner .form-group .form-select.status-won {
  background-color: #59c176;
}
.dashboard-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(33, 33, 33, 0.49);
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  transition: all 0.4s ease-in-out;
}
.dashboard-backdrop.show,
.dashboard-popup.show {
  opacity: 1;
  visibility: visible;
  padding: 150px 0;
  align-items: baseline;
}
.dashboard-popup {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: 999;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}
.dashboard-popup-inner {
  background-color: #fff;
  border-radius: 16px;
  padding: 24px;
  min-width: 60vw;
  position: relative;
  z-index: 2;
}
.dashboard-popup-close {
  position: absolute;
  top: 10px;
  right: 7px;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 10px;
  color: #747b87;
  transition: all 0.4s ease-in-out;
}
.dashboard-popup-close:hover {
  transform: rotate(180deg);
}
.top-employee-popup-header {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #eeeeee;
}
.top-employee-user {
  display: flex;
  align-items: center;
}
.top-employee-user-image {
  display: inline-block;
  margin-right: 16px;
  vertical-align: middle;
  position: relative;
}
.top-employee-user-image img {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 100px;
  border: 2px solid #f29903;
}
.top-employee-user-image::after {
  content: "";
  width: 30px;
  height: 30px;
  background-image: url(../img/star.svg);
  background-repeat: no-repeat;
  background-size: 30px;
  position: absolute;
  top: -12px;
  right: 8px;
}
.top-employee-user h2 {
  display: block;
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
  color: #212121;
  margin-bottom: 4px;
}
.top-employee-user p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: #212121;
}
.dashboard-popup-title {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  color: #212121;
}
.top-employee-count {
  border-width: 1px 1px 2px 1px;
  border-style: solid;
  border-color: #ededed;
  border-radius: 12px;
  padding: 33px 24px;
}
.top-employee-count-row {
  display: flex;
  flex-wrap: wrap;
}
.top-employee-count-icon {
  width: 40px;
  height: 40px;
  border-radius: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-right: 16px;
  color: #fff;
}
.is-total-won .top-employee-count-icon {
  background-color: #66bf7a;
}
.top-employee-count-count {
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  font-size: 36px;
  line-height: 1.2;
  color: #4b5360;
  display: block;
  margin-bottom: 8px;
}
.top-employee-count-label {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.1;
  color: #4b5360;
  margin: 0;
}
.is-total-appraisals .top-employee-count-icon {
  background-color: #51bdd9;
}
.is-total-amount .top-employee-count-icon {
  background-color: #f19802;
}
.top-employee-popup .dashboard-card {
  border-radius: 16px;
  padding: 8px 16px;
  max-height: 30vh;
  overflow: auto;
}
.appraisal-head-wrap .btn-icon {
  width: 40px;
  height: 40px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  color: #212121;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-right: 16px;
}
.dashboard-button-box .btn-icon:hover,
.appraisal-head-wrap .btn-icon:hover {
  background-color: #e3e3e3;
}
.appraisal-head-wrap .page-title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 1;
  color: #485363;
}
.appraisal-head-title {
  display: flex;
  align-items: center;
}
.appraisal-head-buttons .btn:not(:first-child) {
  margin-left: 16px;
}
.appraisal-head-buttons .btn i {
  margin-right: 11px;
}
.nav-pills .nav-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.19;
  color: #000;
  background-color: #f1f1f1;
  padding: 16px 48px;
  text-transform: capitalize;
}
.appraisal-tabs .nav {
  display: inline-flex;
  background-color: #fff;
  border-radius: 30px;
}
.appraisal-tabs .nav-pills .nav-link.active {
  color: #616161;
  background-color: #fff;
}
.appraisal-tabs .nav-pills .nav-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.19;
  color: #000;
  background-color: #f1f1f1;
  padding: 16px 48px;
  text-transform: capitalize;
  border: 2px solid #fff;
  transition: all 0.4s ease-in-out;
}
.appraisal-tabs .nav-pills .nav-item:nth-child(1) .nav-link {
  border-radius: 24px 0px 24px 0px;
}
.appraisal-tabs .nav-pills .nav-item:nth-child(2) .nav-link {
  border-radius: 0px 24px 0px 24px;
}
.appraisal-tabs .tab-pane {
  padding: 24px;
  background-color: #ffffff;
  box-shadow: 0px 61px 34px rgba(0, 0, 0, 0.05);
  border-radius: 0px 24px 24px 24px;
}
.appraisal-form-inner .form-group {
  padding: 9px 24px;
  border: 1px solid #e6e6e6;
  border-radius: 50px;
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.appraisal-form-inner .col-form-label {
  padding: 0 10px 0 0;
  margin-right: 10px;
  border-right: 1px solid #d7d7d7;
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.18;
  color: #78818d;
}
.appraisal-form-inner .form-control {
  border: none;
  outline: none;
  box-shadow: none !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.18;
  color: #212121;
  padding: 6px 0;
  background-color: transparent;
}
.appraisal-form-inner .form-select {
  border: none;
  outline: none;
  box-shadow: none !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.18;
  color: #212121;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 0px;
  background-color: transparent;
  background-image: url(../img/svg/chevron-down-thin.svg);
  background-position: right center;
}
.appraisal-tabs hr {
  background-color: #e2e2e2;
}
.vehicle-photos-upload {
  background-color: #f7f7f7;
  border: 2px dashed #e6e6e6;
  border-radius: 8px;
  font-size: 26px;
  color: #adadad;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 124px;
}
.vehicle-photo-wrap {
  position: relative;
}
.vehicle-photo-wrap img {
  height: 124px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.vehicle-photo-wrap .icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  height: 25px;
  width: 25px;
  border-radius: 50px;
  color: #4b5360;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vehicle-photos .card-title {
  color: #4b5360;
}
.manager-details-user {
  display: flex;
  align-items: center;
}
.manager-image img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100px;
  margin-right: 16px;
}
.manager-info h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  color: #212121;
  display: block;
  margin-bottom: 4px;
}
.manager-info span {
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #4b5360;
}
.offer-details-inner .form-group {
  padding: 12px;
  border: 1px solid #e6e6e6;
  border-radius: 12px;
}
.manager-details-inner {
  margin-top: 32px;
  margin-bottom: 41px;
}
.offer-notes .appraisal-form-head {
  margin-top: 40px;
  margin-bottom: 25px;
}
.offer-details-inner .row {
  margin-bottom: 13px;
}
.offer-details-inner .form-group .form-label {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #737b87;
  display: block;
}
.offer-details-inner .icon {
  color: #212121;
}
.offer-details-inner .form-group .form-control,
.offer-details-inner .form-group .form-select {
  padding: 8px 15px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 60px;
  border: none;
  outline: none;
  box-shadow: none !important;
  line-height: 1.2;
}
.offer-details-inner .form-group .form-control {
  color: #212121;
  background-color: #f2f2f2;
  font-weight: 700;
}
.offer-details-inner .form-group .form-select {
  color: #fff;
  background-image: none;
  text-align: center;
}
.offer-details-inner .form-group .form-select option {
  background-color: #fff;
  color: #212121;
}
.offer-note {
  padding: 16px;
  border: 1px solid #e6e6e6;
  border-radius: 16px;margin-bottom: 20px;
}
.offer-note:last-child{margin-bottom: 0;}
.pagination {
  margin-top: 24px;
}
.pagination .nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: inherit;
}
.swal2-actions .btn + .btn {
  margin-left: 10px;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  background-color: #fb4646 !important;
}
.swal2-icon.swal2-error {
  color: #fb4646 !important;
  border-color: rgb(251 70 70 / 70%) !important;
}
.swal2-icon.swal2-warning {
  border-color: rgb(255 190 51 / 60%) !important;
  color: #ffbe33 !important;
}
.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #55ba1c !important;
}
.swal2-icon.swal2-success .swal2-success-ring {
  border-color: rgb(97 222 27 / 60%) !important;
}
.swal2-icon.swal2-info {
  border-color: rgb(15 185 240 / 60%) !important;
  color: #0fb9f0 !important;
}
.table > :not(:first-child) {
  border-top: none;
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #ef5166;
}
.manager-image {
  display: inline-block;
  position: relative;
}
.manager-image input {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
}
.manager-image i {
  width: 26px;
  height: 26px;
  background-color: #ef5166;
  border-radius: 60px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 2px;
  right: 12px;
  font-size: 12px;
}

/*---new-style---*/

ol, ul {list-style: none;margin: 0;padding: 0;}
.offer-note-image{width: 39px;height: 39px;overflow: hidden;border-radius: 50%;background: #F3F3F3;position: relative;}
.offer-note-image img{object-fit: cover;}
.offer-note-image .icon{font-size: 20px;position: absolute;color: #babbbf; line-height: 0;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.offer-note-head{display: flex;flex-wrap: wrap;justify-content: space-between;border-bottom: 1px solid #F0F0F0;padding-bottom: 16px;padding-bottom: 9px;margin-bottom: 13px;}
.offer-note-info p{font-size: 16px;line-height: 1;color: #212121;display: block;font-weight: 700;margin-bottom: 0;}
.offer-note-info span{font-size: 14px;line-height: 1;color: rgba(33, 33, 33, 0.6);font-weight: 400;}
.noted-action-buttons{font-size: 0;}
.noted-action-buttons .btn-icon ~ .btn-icon{margin-left: 12px;}
.noted-action-buttons .btn-icon{border: 1px solid #EDEDED;border-radius: 16px;font-size: 14px;color: #8C96A6;width: 32px;height: 32px;
display: inline-block;position: relative;transition: all 0.4s ease-in-out;vertical-align: middle;}
.noted-action-buttons .btn-icon:hover{color: #ef5166;}
.noted-action-buttons .btn-icon .icon{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.offer-note-detail{padding-right: 15px;}
.offer-note-detail p{color: #16181C;font-size: 18px;line-height: 1.2;}
.offer-note .col-auto{padding-right: 8px;}
.offer-note .col{padding-left: 8px;}
.offer-note-input{margin-top: 20px;}
.offer-note-input .form-control{border: 1px solid #E6E6E6;background: #F5F5F5;min-height: 51px;padding: 10px 16px;border-radius: 12px;
color: #000;font-weight: 500;font-size: 16px;line-height: 1.4;}
.offer-note-input .form-control::-webkit-input-placeholder {color: #4B5360;}
.offer-note-input .form-control::-moz-placeholder {color: #4B5360;}
.offer-note-input .form-control:-ms-input-placeholder {color: #4B5360;}
.offer-note-input .form-control:-moz-placeholder {color: #4B5360;}
.offer-note-input .form-control:focus{border-color: #ced4da;box-shadow: none;}
.offer-note-input .col-auto{margin-left: 12px;}
.offer-note-input .btn-icon{background: #fff;border-color: #F1F1F1; border-style: solid;border-radius: 12px; border-width: 1px 1px 4px 1px;
color: #212121;font-size: 16px;width: 50px;height: 50px;position: relative;transition: all 0.4s ease-in-out;}
.offer-note-input .btn-icon:hover{background-color: #59C176;color: #fff;border-color: #4BAF67;}
.offer-note-input .btn-icon .icon{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.upload-button{position: relative;z-index: 2;border: 1px solid #EDEDED;border-radius: 50px;min-width: 147px;padding: 9px 10px;font-size: 16px;color: #212121;
line-height: 1.4;display: inline-block;vertical-align: middle;transition: all 0.4s ease-in-out;}
.upload-button:hover{background: #fafafa;}
.upload-button .icon{font-size: 15px;color: #8C96A6;}
.browse-btn{position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 100%;height: 100%;font-size: 0;padding: 0;border: none;background: transparent;
opacity: 0;cursor: pointer;}
.vehicleuplaodcol{margin-top: 24px;}
.history-date{text-align: center;position: relative;margin-top: 38px;margin-bottom: 35px;}
.history-date .date-Title{font-size: 18px;font-weight: 500;color: #4B5360;display: inline-block;overflow: hidden;vertical-align: middle;
background: #fff;position: relative;z-index: 3;padding: 0 16px;}
.history-date:before{content: "";position: absolute;top: 50%;height: 1px;width: 100%;display: block;border-bottom: 1px solid #F1F1F1;right: 0;left: 0;}
.history-notes-inner .offer-note-info p{font-size: 20px;margin-bottom: 6px;}
.history-notes-inner .offer-note-info p span{color: #212121;font-weight: 400;}
.history-notes-inner .offer-note-info span{font-size: 16px;}
.history-notes-inner .offer-note-head{padding-bottom: 0;border-bottom: none;}
.history-note-detail .form-group .amoiunt{padding: 8px 12px;border-radius: 50px;background: #F2F2F2;font-weight: 700;font-size: 14px;line-height: 1.3;
color: #212121;display: inline-block;}
.history-note-detail.offer-details-inner .form-group .form-label{margin-bottom: 15px;}
.historynotecol{width: auto;}
.mw-162{min-width: 162px;}
.mw-168{min-width: 168px;}
.mw-153{min-width: 153px;}
.w-442{width: 442px;}
.arrow-icon{width: 40px;height: 40px;border: 1px solid #F9F9F9;border-radius: 50%;font-size: 16px;color: #33577B;position: relative;
display: inline-block;transform: rotate(180deg);}
.arrow-icon .icon{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.history-notes-inner .form-group ul{padding: 0;margin-bottom: 0;font-size: 0;}
.history-notes-inner .form-group ul li{list-style: none;font-size: 16px;font-weight: 500;color: #212121;line-height: 1;
display: inline-block;vertical-align: top;width: 50%;margin-bottom: 33px;}
.history-notes-inner .form-group ul li span{color: #78818D;border-right: 1px solid #D7D7D7;margin-right: 10px;padding-right: 10px;
display: inline-block;width: 58px;}
.history-notes-inner .form-group ul li b{font-weight: 500;width: calc(100% - 73px);display: inline-block;vertical-align: top;}
.history-notes-inner .form-group ul li ~ li{margin-bottom: 0;}
.history-notes-inner .form-group ul li:last-child{margin-bottom: 0;}
.offer-details-inner.history-note-detail .row{margin-bottom: 0;}

.pagination{display: block;text-align: center;}
.nav-links{display: inline-block;font-size: 0;}
.page-numbers.prev, .page-numbers.next{line-height: 1;font-size: 16px;color: #747B87;}
.page-numbers .icon{position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);}
.page-numbers.prev{margin-right: 26px;margin-left: 0;}
.page-numbers.next{margin-left: 26px;margin-right: 0;}
.page-numbers{width: 38px;height: 38px;border: 1px solid #E4E4E4;border-radius: 50%;display: inline-block;vertical-align: top;position: relative;
line-height: 38px;font-family: 'Work Sans', sans-serif;font-size: 16px;color: #747B87;font-weight: 600;transition: all 0.4s ease-in-out;margin: 0 4px;}
.page-numbers.current,.page-numbers:hover{border-color: transparent;background: #EF5166;color: #fff;}
.dashboard-latest-appraisals .dashboard-navbar-search{display: inline-block;vertical-align: top;}
.dashboard-latest-appraisals .dashboard-navbar-search .form-control{width: 19vw;}
.page-dec{color: #747B87;font-size: 16px;font-family: "Roboto", sans-serif;font-weight: 400;line-height: 1;}
.dashboard-navbar-date-filter{display: inline-block;vertical-align: top;}
.dashboard-navbar-date-filter .date-filter{border: 1px solid #EDEDED;border-radius: 50px;padding: 8px 16px;font-size: 16px;color: #212121;font-weight: 500;
line-height: 1.4;text-align: center;display: inline-block;transition: all 0.4s ease-in-out;}
.dashboard-navbar-date-filter .date-filter .icon{font-size: 14px;color: #9A9A9A;margin-left: 5px;}
.dashboard-navbar-date-filter .date-filter:hover{background: #f7f7f7;}
.dashboard-appraisals .page-title{margin-bottom: 10px;}
.form-control:focus{box-shadow: none;}
.get-help-popup .dashboard-popup-inner{background: #FBFBFB;border-radius: 24px;border: 2px solid #FFFFFF;width: 840px;min-width: auto; padding: 46px 48px;}
.get-help-Title{font-size: 32px;font-weight: 700;line-height: 1.2;color: #212121;margin-top: 0px;margin-bottom: 53px;}
.question-search{width: 542px;display: block;margin: auto;margin-bottom: 48px;}
.question-search .form-group{position: relative;background: #FFFFFF;box-shadow: 0px 9px 22px rgba(81, 105, 124, 0.1);border-radius: 8px;}
.question-search .form-group .form-control{display: inline-block;font-weight: 500;font-size: 14px;line-height: 1.4;color: #212121;
width: 100%;border: none;position: relative;padding: 10px 20px;min-height: 50px;padding-right: 40px;font-family: 'Poppins', sans-serif;}
.question-search .form-group .form-control::-webkit-input-placeholder {color: #747B87;font-weight: 500;}
.question-search .form-group .form-control::-moz-placeholder {color: #747B87;font-weight: 500;}
.question-search .form-group .form-control:-ms-input-placeholder {color: #747B87;font-weight: 500;}
.question-search .form-group .form-control:-moz-placeholder {color: #747B87;font-weight: 500;}
.question-search .form-group .form-control::placeholder {color: #747B87;font-weight: 500;}
.question-search .form-group .search-button{display: inline-block;font-size: 16px;color: #cccccc;position: absolute;top: 0;bottom: 0;right: 0;margin: auto;
border: none;background: transparent;width: 54px;}
.helpyouboxMain{margin-bottom: 100px;}
.helpyoubox{background: #FFFFFF;box-shadow: 0px 24px 74px rgba(0, 0, 0, 0.03);border-radius: 16px;padding: 22px 20px;height: 100%;}
.helpyouboximg{width: 20px;height: 20px;margin-left: auto;margin-right: auto; margin-bottom: 10px;}
.helpyouboxTitle{font-weight: 600;font-size: 12px;font-family: 'Poppins', sans-serif;color: #4B5360;}
.helpyoubox p{font-size: 12px;font-weight: 400;line-height: 1.2;color: #747B87;}
.faqMian .secHead{max-width: 65%;margin-left: auto;margin-right: auto;margin-bottom: 56px;}
.secHead{margin-bottom: 20px;}
.secHead .secTitle{font-weight: 700;font-size: 32px;color: #212121;}
.secHead p{font-weight: 400;font-size: 16px;color: #8E8E8E;line-height: 1.2;}
.faq-accordionMian{}
.faq-accordionMian .accordion-item{border-radius: 16px;overflow: hidden;margin-bottom: 12px;border: 0;}
.faq-accordionMian .accordion-item:last-child{margin-bottom: 0;}
.faq-accordionMian .accordion-button{font-weight: 500;font-size: 20px;line-height: 1.4;font-family: 'Poppins', sans-serif;color: #4B5360;
padding: 19px 24px;padding-right: 55px;}
.accordion-button:focus{border-color: transparent;box-shadow: none;}
.faq-accordionMian .accordion-button:not(.collapsed){background-color: transparent;color: #212121;box-shadow: none;}
.faq-accordionMian .accordion-body{padding: 24px 24px;padding-top: 0;}
.faq-accordionMian .accordion-body.cmsCon p{color: #212121;line-height: 1.4;}
.faq-accordionMian .accordion-button::after{content: "";position: absolute;left: inherit;top: 0;bottom: 0;right: 32px;margin: auto;
display: inline-block;background-image: url(../img/svg/plus.svg);object-fit: contain;object-position: center;
background-size: 10px;background-repeat: no-repeat;width: 10px;height: 10px;}
.faq-accordionMian .accordion-button:not(.collapsed)::after{background-image: url(../img/svg/minus.svg);background-repeat: no-repeat;height: 2px;}
.faq-accordionMian .accordion-button::before{position: absolute;content: "";width: 26px;height: 26px;background: #F7F7F7;border-radius: 50%;
top: 0;right: 24px;bottom: 0;margin: auto;}
.contactMian .secHead{margin-top: 39px;}
.contactMian .secHead .secTitle{margin-bottom: 7px;}
.contactMian .secHead p{color: #4B5360;font-weight: 500;line-height: 1.4;}
.contactMian .secHead .btn{min-width: 132px;font-size: 14px;padding: 11px 24px 8px;}
.secHead .secTitle.size24{font-size: 24px;}

.continfo{margin-bottom: 42px;}
.continfo ul li{display: block;margin-bottom: 27px;line-height: 0;}
.continfo .infodetail{font-size: 12px;font-weight: 500;line-height: 1;font-family: 'Poppins', sans-serif;color: #212121;position: relative;padding-left: 22px;
transform: all 0.4s ease-in-out;}
.continfo a.infodetail:hover{color: #EF5166;}
.continfo .infodetail .icon{color: #747B87;position: absolute;top: 2px;left: 0;}
.continfo ul li:last-child{margin-bottom: 0;}
.contactinfoMian .secHead{margin-bottom: 42px;max-width: 85%;}
.contactinfoMian .secHead p{font-size: 12px;}

.socialMeadia a{width: 32px;height: 32px;border-radius: 50%;background: rgba(163, 163, 163, .1);font-size: 13px; color: #A3A3A3;display: inline-block;position: relative;transition: all 0.4s ease-in-out;
margin: 0 12px;}
.socialMeadia a:first-child{margin-left: 0px;}
.socialMeadia a:last-child{margin-right: 0px;}
.socialMeadia a .icon{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);line-height: 0.8;}
.socialMeadia .instagram:hover{background: #8a3ab9;color: #fff;}
.socialMeadia .twitter:hover{background: #00acee;color: #fff;}
.socialMeadia .youtube:hover{background: #FF0000;color: #fff;}

.contact-inner{box-shadow: 0px 24px 74px rgb(0 0 0 / 3%);border-radius: 24px;background-color: #fff;padding: 40px 40px;}
.contactinforow .contactinfocol:first-child{width: 59%;}
.contactinforow .contactinfocol:last-child{width: 41%;}
.contact-inner .secHead{margin-bottom: 22px;}
.contactinfoMian .secHead .secTitle{margin-bottom: 12px;}
.contact-form .login-form-inner .form-label{font-weight: 600;font-family: 'Poppins', sans-serif;}
.contact-form .login-form-inner .btn{margin-bottom: 0;}
.contact-form .login-form-inner .form-control.textareaField{border-radius: 16px;min-height: 126px;}

.contact-form .login-form-inner .form-control::-webkit-input-placeholder {color: #4B5360;}
.contact-form .login-form-inner .form-control::-moz-placeholder {color: #4B5360;}
.contact-form .login-form-inner .form-control:-ms-input-placeholder {color: #4B5360;}
.contact-form .login-form-inner .form-control:-moz-placeholder {color: #4B5360;}
.contactimg{margin-bottom: -1px;}
.contactdetails{padding-top: 41px;}

.dashboard-navbar-month{min-width: auto;display: inline-block;vertical-align: top;}
.dashboardml ~ .dashboardml{margin-left: 14px;}
.dashboard-navbar-month .form-select{font-weight: 500;background-image: url(../img/svg/down-arrow.svg);background-size: 9px;}
.dashboard-navbar-filter{min-width: 48px;display: inline-block;}
.dashboard-navbar-filter .filter{border: 1px solid #EDEDED;padding: 7px 15px;
border-radius: 50px;position: relative;display: inline-block;transition: all 0.4s;}
.dashboard-navbar-filter .filter:hover{background: #f7f7f7;}
.dashboard-navbar-filter .filter .icon{font-size: 16px;color: #9A9A9A;}
.dashboard-export .btn{font-size: 14px;padding: 11px 22px 8px;vertical-align: middle;}
.dashboard-export .btn .icon{font-size: 15px;display: inline-block; vertical-align: top;}
.appraisal-head-wrap .page-title{margin-bottom: 10px;}
.appraisal-head-wrap .page-dec{font-weight: 600;}
.appraisal-head-wrap.notifications-head-wrap .page-title{font-weight: 500;font-size: 40px;color: #212121;}
.appraisal-head-wrap.notifications-head-wrap .page-dec{font-weight: 400;}

.notification{background: #FFFFFF;box-shadow: 0px 24px 74px rgba(0, 0, 0, 0.03);border-radius: 24px;max-width: 920px;margin-left: auto;
margin-right: auto;padding: 32px;padding-top: 45px;}
.notification-Title{font-weight: 600;font-size: 24px;color: #4B5360;display: block;margin-bottom: 45px;}
.ffr{font-family: 'Roboto', sans-serif;}
.ffp{font-family: 'Poppins', sans-serif;}
.dashboard-notification{border: 1px solid #EDEDED;border-radius: 16px;}
.dashboard-notification .table thead th, .dashboard-notification .table tbody td{padding: 17px 16px;color: #1D1E1F;font-weight: 500;font-size: 14px;line-height: 1;white-space: nowrap;
text-align: center;border-right: 1px solid #EDEDED;border-bottom: 1px solid #EDEDED;box-shadow: none;}
.dashboard-notification .table thead th:last-child, .dashboard-notification .table tbody td:last-child{border-right: 0;}
.dashboard-notification .table > :not(:last-child) > :last-child > *{border-bottom-color: #EDEDED;}
.dashboard-notification .table tbody tr td{font-weight: 400;}
.dashboard-notification .table tbody tr:last-child td{border-bottom: 0;}
.dashboard-notification .table tbody tr td:first-child{text-align: left;}

.customCheckbox{position: relative;line-height: 0;vertical-align: text-bottom;}
.customCheckbox input[type="checkbox"]{position: absolute; left: 0; opacity: 0; top: 0; left: 0;} 
.customCheckbox > .lable-text{display: inline-block; width: 18px;height: 18px; position: relative;cursor: pointer;}
.customCheckbox > .lable-text:before{content: ""; position: absolute; left: 0; top: 0; bottom: 0; margin: auto;  width: 18px; height: 18px; border: 2px solid #CCCCCC; background: #ffffff; border-radius: 5px;} 
.customCheckbox > .lable-text:after{content: ""; -webkit-transform: translate(4px,5.38px) rotate(-45deg); transform: translate(4px,5.38px) rotate(-45deg); width: 8px; height: 5px; border: 2px solid #fff; border-top-style: none; border-right-style: none; opacity: 0; transition: all .4s ease-in-out; position: absolute; left: 1px; top: 0px; bottom: 13px; margin: auto;} 
.customCheckbox.darkCheck > .lable-text:after{border-color: #28304b;}
.customCheckbox input[type="checkbox"]:checked+.lable-text:before { background-color: #59C176; border-color: #59C176;}
.customCheckbox input[type="checkbox"]:checked+.lable-text:after { opacity: 1;}

.dashboard-switch-button{display: inline-block;vertical-align: top;}
.dashboard-switch-button .switch-buttonmain{border: 1px solid #EDEDED;border-radius: 50px;padding: 6px 22px;padding-right: 10px; font-size: 12px;color: #433C52;font-weight: 500;
line-height: 1.2;text-align: left;display: inline-block;transition: all 0.4s ease-in-out;background: #fff;}
.switch-button-text{display: inline-block;vertical-align: middle;}
.switch-button{display: inline-block;vertical-align: middle;margin-left: 5px;}
.switch-button .switch {display: inline-block;position: relative;width: 42px;height: 23px;border-radius: 50px;background: #ccc;transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
vertical-align: middle;cursor: pointer;}
.switch-button .switch::before {content: '';position: absolute;top: 2px;right: 21px;width: 19px;height: 19px;background: #fff;border-radius: 50%;
transition: right 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);}
.switch-button .switch:active::before {box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(128,128,128,0.1);}
.switch-button input:checked + .switch {background: #66BF7A;}
.switch-button input:checked + .switch::before {right: 2px;left: unset;background: #fff;}
.switch-button input:checked + .switch:active::before {box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(0,150,136,0.2);}

.appraisal-form-inner .form-control.password { padding-left: 26px;}
.appraisal-form-inner .form-control.password {background-image: url(../img/svg/password-2.svg);background-repeat: no-repeat;background-position: center left;}

.notification.edit-profile{padding: 32px;}
.edit-profile .manager-image img{width: 80px;height: 80px;}
.edit-profile .manager-info h4{font-size: 24px;}
.edit-profile .btn{font-weight: 700;}
.edit-profile .btn .icon{margin-right: 6px;}
.edit-profile .btn .icon.icon-edit{color: rgba(75, 83, 96, 0.5);}
.edit-profile .manager-details-inner{margin-bottom: 22px;}
.edit-profile .appraisal-form-inner .form-group{padding: 9px 16px;}
.login-inner.add-new-user-inner{margin-right: auto;max-width: 538px;padding: 32px;padding-bottom: 38px;}
.login-head{border-bottom: 1px solid #F4F4F4;}
.add-new-user-inner .login-head{padding-bottom: 30px;margin-bottom: 30px;}
.add-new-user-inner .login-text{margin-bottom: 0;}
.form-control.form-select{background-image: url(../img/svg/chevron-down-thin.svg);background-position: right 20px center;background-size: 10px;
padding-right: 35px;}
.notice{position: relative;padding-left: 30px;color: #212121;margin-bottom: 24px;}
.notice p{color: #212121;}
.notice:before{position: absolute;content: "";width: 20px;height: 20px;background: url(../img/svg/notice.svg) no-repeat;top: 2px;left: 0;}
.intl-tel-input{padding: 0 10px 0 0;margin-right: 10px;border-right: 1px solid #d7d7d7;}
.login-form-inner .form-control.phone{padding-left: 38px;background-image: url(../img/svg/phone-line.svg);background-size: 12px;}
.tel-country-list{font-weight: 500;font-size: 16px;color: #78818D;border: none;}
.tel-country-list:focus{box-shadow: none;border: none;outline: none;}
.inputField{border: none; outline: none;box-shadow: none !important;font-weight: 500;font-size: 16px;line-height: 1.18;color: #212121;padding: 0;
background-color: transparent;margin: 0;}
.add-new-user-inner .btn{font-weight: 500;}
.add-new-user-inner .btn .icon{margin-right: 5px;}
.login-form-login-link a.cancel{margin-left: 0;font-weight: 400;text-decoration-line: none;}

.notifitimedaytext{font-weight: 500;font-size: 14px;color: #212121; display: inline-block;vertical-align: middle;}
.noted-action-buttons .status-new{background: #EF5166;display: inline-block;vertical-align: middle;}
.dashboard-notification .offer-note-info p{font-size: 18px;}
.dashboard-notification .offer-note-info p span{font-weight: 500;font-size: 18px;color: #212121;}
.dashboard-notification .offer-note-head{padding-bottom: 0;border-bottom: 0;margin-bottom: 0;}
.dashboard-notification .offer-note-detail p{font-size: 16px;}
.dashboard-notification .offer-note-detail{max-width: 80%;}
.dashboard-notification .noted-action-buttons .btn-icon{color: #212121;}
.dashboard-notification .noted-action-buttons .btn-icon:hover{color: #EF5166;}
.offer-note.bglight{background: #F8F8F8;}
.dashboard-notification .offer-note-image{width: 48px;height: 48px;}
.dashboard-notification .history-date{margin-top: 30px;}
.login-section .login-form-inner .btn{/*border-width: 0;*/padding: 17px 24px 14px;}
.login-section .login-form-register-link{border: none;background-color: #EEFCFF;}
.login-section .login-form-register-link .icon{font-size: 20px;margin-right: 5px;}
.login-section .login-inner{padding-bottom: 70px;}
.user-reset-password-section{background: #FBFBFB;}

.user-reset-password-section{padding-top: 110px;padding-bottom: 38px;}
.user-reset-password-section .login-inner{margin-bottom: 40px;max-width: 538px;margin-right: auto;}
.border-width-0{border: 0;}
.user-reset-password-section .login-form-inner .btn{padding: 18px 24px 15px;margin-bottom: 15px;}

.form-group .form-control:disabled, .form-control[readonly]{background-color: #F8F8F8;border: none;color: #4D4F53;}
.form-group .form-control.disabled{background-color: #F8F8F8;border: none;color: #4D4F53;}
.form-group .form-control.disabled .inputField{font-weight: 700;color: #4D4F53;}
.form-group.disabled{background-color: #F8F8F8;border: none;color: #4D4F53;}

.inputimg{width: 62px;display: flex;}
.inputflex{flex: 1 1 auto;width: 1%;}
.form-group-dec{font-weight: 500;font-size: 12px;color: #0194BA;margin-top: 8px;}
.form-group-dec a{color: #0194BA;text-decoration-line: underline;}
.form-group-dec a:hover{color: #d8384d;}
.no-appraisalimg{max-width: 171px;margin-left: auto;margin-right: auto;margin-bottom: 30px;}
.no-appraisalMain{padding-top: 136px;}
.no-appraisalMain .secHead{margin-bottom: 28px;}

.login-inner.add-new-appraisal-inner{margin-right: auto;max-width: 866px;padding: 32px;padding-bottom: 40px;}

.add-new-appraisal-inner hr{border-bottom: 1px solid #e8e8e8;}
.add-new-appraisal-inner .secHead{margin-bottom: 45px;}
.new-appraisal-card-submit{margin-top: 30px;}
.new-appraisal-card-submit .btn{min-width: 100%;}
.newcard-submitrow{margin: 0 -8px;}
.newcard-submitcol{padding: 0 8px;}
.new-appraisal-card-submit .form-privacy-policy{margin-top: 43px;}
.add-new-appraisal-inner .vehicle-photos{padding: 16px 0; display: block;}
hr{border-bottom: 1px solid #e8e8e8;}

.form-group{display: block;position: relative;}
.form-group .form-error, .form-group label.error {position: absolute; right: 0; top: -20px; width: auto; height: auto; margin: 0; font-size: 11px!important; line-height: 11px!important; background: #fb0101; padding: 5px 5px 4px!important; color: #fff!important;}
.form-group .form-error:after, .form-group label.error:after {border-color: #fb0101 transparent transparent; -o-border-image: none;
 border-image: none; border-right: 6px solid transparent; border-style: solid; border-width: 6px; content: ""; height: 0; left: 5px; position: absolute; top: 100%; width: 0;}

.form-group input:-webkit-autofill, .form-group input:-webkit-autofill:hover, 
.form-group input:-webkit-autofill:focus, .form-group textarea:-webkit-autofill, .form-group textarea:-webkit-autofill:hover, 
.form-group textarea:-webkit-autofill:focus, .form-group select:-webkit-autofill, .form-group select:-webkit-autofill:hover,
.form-group select:-webkit-autofill:focus{background:transparent;-webkit-text-fill-color:#000;-webkit-box-shadow:0 0 0px 1000px transparent inset;transition:background-color 5000s ease-in-out 0s}

.appraisal-listing-filter .page-title{margin-bottom: 10px;}
.appraisal-car-listMian{font-size: 0;}
.appraisal-listing-popup .dashboard-popup-close{background: #fff;width: 34px;height: 34px;font-size: 13px;color: #000;display: inline-block;top: -17px;right: -17px;
border-radius: 50%;line-height: 34px;z-index: 2;}
.dashboard-popup-inner.appraisal-listing-popup{width: 1087px;min-width: 1087px;padding: 0;border-radius: 24px;}
.carlistimg{position: relative;display: block;width: 100%; padding-bottom: 49%;}
.carlistimg img{position: absolute;top: 0;right: 0;bottom: 0;left: 0;width: 100%;height: 100%;max-width: 100%;max-height: 100%;object-fit: cover;
object-position: center;border-radius: 24px;}


/*  Slick slider */

.carlistslide .slick-slide{outline: none;}

.carlistslide .slick-dots{display: block; text-align: center; margin-top: 3.5rem;}

.carlistslide .slick-dots li{ display: inline-block; width:auto; list-style: none; padding: 0 4px;}

.carlistslide .slick-dots li button{font-size:0; border: none; background: rgba(255, 255, 255, 0.5); width: 8px; height: 8px; border-radius: 50%; -moz-transition: all 0.4s ease-in-out; -webkit-transition: all 0.4s ease-in-out;  transition: all 0.4s ease-in-out; cursor: pointer; outline: none;
position: relative;padding: 0;}

.carlistslide .slick-dots li.slick-active button, .carlistslide .slick-dots li button:hover{background: #fff;}

.carlistslide .slick-prev{background: #fff;width: 44px;height:44px;border-radius:50%;position: absolute;left: 37px;top:50%;margin-top:-20px;z-index:999;font-size:0;border:none;outline-color: transparent;
transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out, transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;}

.carlistslide .slick-prev:before{content:"\e908";width: auto;height: auto;font-family: "icon-pack-v1" !important;
  font-style: normal !important;font-size: 16px;line-height: 44px;
  font-weight: 700;position:absolute;left:0px;right:0;top:0px;bottom:0;margin:auto;}

.carlistslide .slick-next{background:#fff; width:44px; height:44px; border-radius:50%; position: absolute; right:37px; top:50%; margin-top:-20px; z-index:999; font-size:0; border:none; outline-color: transparent;
transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out, transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;}

.carlistslide .slick-next:before{content:"\e909"; width:auto; height:auto;font-family: "icon-pack-v1" !important;
  font-style: normal !important;font-size: 16px;line-height: 44px;
  font-weight: 700; position:absolute; left:0; right:0px; top:0px; bottom:0; margin:auto;}

.carlistslide .slick-prev:arrow .slick-next:arrow{ color: #000000;}

.carlistslide .slick-prev:hover, .slick-next:hover {color: #bcbcbc;}
/* Slick slider End */


.carlistslide .slick-dots{position: absolute;margin-top: 10px;bottom: 29px;left: 0;right: 0;}
.appraisalsvehicle{position: relative;z-index: 2;transition: all 0.4s ease-in-out;}
.appraisalsvehicle:hover:before{content: ""; position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 14px;height: 14px;background: url(../img/svg/imgzoom.svg) no-repeat;
margin: auto;}

.show-earningsMain{background: linear-gradient(92.29deg, #45A35A 8.73%, #E1F8A0 103.41%);border-radius: 16px;max-width: 753px;margin-left: auto;margin-right: auto;
padding: 20px 30px;margin-bottom: 24px;margin-top: 160px;}
.earnings-head{}
.earnings-head .earnings-Title{position: relative;padding-left: 39px;font-weight: 500;font-size: 24px;color: #fff;margin: 0;}
.earnings-head .earnings-Title .icon{position: absolute;top: 3px;left: 0;font-size: 22px;}
.show-earningsMain .switch-button input:checked + .switch{background: #fff;}
.show-earningsMain .switch-button input:checked + .switch::before{background: #66BF7A;right: 29px;}
.show-earningsMain .switch-button .switch{width: 52px;height: 26px;}
.show-earningsMain .switch-button .switch::before{top: 4px;right: 4px;background: #66BF7A;}
.show-earningsMain .switch-button .switch{background: #fff;}

.my-edit-profile{margin-top: 170px;max-width: 920px;}
.btn ~ .btn{margin-left: 11px;}

.one-purchaseMian{padding: 5px 0;}
.one-purchaseMian .purchaseTitle{color: #73787C;font-weight: 600;margin-bottom: 20px;}
.one-purchaseMian .usdprice{font-weight: 600;color: #56AC69;font-size: 32px;line-height: 1;margin-bottom: 0;}
.one-purchaseMian .border-dashed{position: relative;display: block;border-bottom: 1px dashed #7592A9;}
.one-purchaseMian .unlimiteduser{font-weight: 500;font-size: 17px;color: #3C4C58;line-height: 1;margin-bottom: 0;}
.one-purchaseMian .purchase-dec{font-size: 12px;line-height: 1.2;color: #4B5360;margin-top: 19px;}
.one-purchaseMian .purchase-dec a{text-decoration-line: underline;color: #4B5360;transition: all 0.4s ease-in-out;}
.one-purchaseMian .purchase-dec a:hover{color: #d8384d;}
.purchaserow{margin: 0 -8px;}
.purchaserow .purchasecol{padding: 0 8px;}
.purchase-section .login-title{margin-bottom: 28px;}
.purchase-section .login-form{margin-top: 24px;}
.purchase-section.login-section .login-inner{padding-bottom: 40px;margin-top: 110px; margin-bottom: 55px;}
.purchase-section .login-copyright{margin-bottom: 30px;}
.form-control::placeholder{color: #4B5360 !important;}

.successfulpayMian{background: #EDFFF1;border: 1px solid #CCF9D6;border-radius: 16px;display: block;padding: 15px 32px;margin-bottom: 32px;}
.successfulimg{max-width: 36px;margin-left: auto;margin-right: auto;margin-bottom: 20px;}
.successfulTitle{font-weight: 700;font-size: 24px;line-height: 1.2;color: #074515;display: block;}
.successfulpayMian p{color: #074515;}
.successful-section .login-title{margin-bottom: 40px;}
.successful-section .btn{margin-bottom: 33px;}
.successful-section.login-section .login-inner{padding-bottom: 55px;}

/*---new-style---*/
